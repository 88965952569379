import { vacationApi } from '@/api/vacation.api';
import { createSelector } from '@reduxjs/toolkit';
import { Vacation } from './vacationModel';
import { RootState } from '@/state/store';

const selectVacationsResult = vacationApi.endpoints.getVacations.select();

const selectSessionId = (_state: unknown, sessionId: string | undefined) => sessionId;

const selectCurrencyPair = (
  _state: unknown,
  _sessionId: string | undefined,
  { currency1, currency2 }: { currency1: string | undefined; currency2: string | undefined },
) => ({ currency1, currency2 });

const emptyVacations: Vacation[] = [];

/**
 * Get all vacations
 */
const selectAllVacations = createSelector(
  selectVacationsResult,
  (vacationsResult) => vacationsResult?.data ?? emptyVacations,
);

export const selectCurrentVacation = createSelector(selectVacationsResult, (vacationsResult) => {
  const currentVacation = vacationsResult?.data?.find((vacation) => vacation.isCurrent);

  return currentVacation === undefined ? null : currentVacation;
});

/**
 * Get Vacations from sessionId
 */
export const selectVacationById = createSelector(
  [selectAllVacations, selectSessionId],
  (allVacations, sessionId) => allVacations.find((vacation) => vacation.id === sessionId),
);

/**
 * Get CurrencyPairs[] from vacations of the given session sessionId
 */
export const selectCurrencyPairsFromSessionIdVacation = createSelector(
  selectVacationById,
  (currentVacation) => currentVacation?.currencyPairs ?? [],
);

/**
 * Get CurreyPairs like EUR/USD that contains Deals[] of vacations of the given session sessionId
 */
export const selectCurrencyPairDealsFromSessionIdVacation = createSelector(
  [selectCurrencyPairsFromSessionIdVacation, selectCurrencyPair],
  (currencyPairs, { currency1, currency2 }) => {
    const [currencyPairWithDeals] = currencyPairs.filter(
      (currencyPair) =>
        currencyPair.currency1 === currency1 && currencyPair.currency2 === currency2,
    );
    return currencyPairWithDeals;
  },
);

export const selectLastVacation = (state: RootState) => state.vacation.lastVacation;
