import { FC } from 'react';
import OpenDealsSelectionButton from '@/components/booking/DealsBulkActionFooter/OpenDealsSelectionButton';
import DeselectButton from '@/components/booking/DealsBulkActionFooter/DeselectButton';
import RefreshButton from '../DealsActions/RefreshButton';
import { BookingStatus, Deal } from '@/features/vacation/vacationModel';
import { DiscardPayload, PrepareRefreshPayload, SavePayload } from '@/api/deals.api';
import { useParams } from 'react-router-dom';
import { ApplySpotRateButton } from '@/components/booking/DealsBulkActionFooter/ApplySpotRateButton';
import SaveButton from '@/components/booking/DealsActions/SaveButton';
import { DealToConvert } from '@/components/booking/Blotter/RatesColumnsHeader';
import { useAppSelector } from '@/hooks/reduxHook';
import { getUserSesameId } from '@/features/user/userSlice';
import { DiscardButton } from '@/components/booking/DealsActions/DiscardButton';

interface OpenDealsBulkActionsFooterProps {
  selectAllOpenDeals: (status: BookingStatus[]) => void;
  deselectAllOpenDeals: () => void;
  selectButtonLabel: string;
  selectedOpenDeals: Deal[];
  currencyPairId: string;
}
const OpenDealsBulkActionsFooter: FC<OpenDealsBulkActionsFooterProps> = ({
  selectAllOpenDeals,
  selectButtonLabel,
  selectedOpenDeals,
  currencyPairId,
  deselectAllOpenDeals,
}) => {
  const { sessionId: vacationId, currency1, currency2 } = useParams();

  const dealsToRefresh: PrepareRefreshPayload[] = selectedOpenDeals.map((openedDeal) => ({
    dealId: openedDeal.id,
    currencyPair: `${currency1}${currency2}`,
    product: openedDeal.product,
    proposedDate: openedDeal.valueDate.proposedDate,
    currency1: currency1!,
    currency2: currency2!,
    vacationId: vacationId!,
  }));

  const dealOwner = useAppSelector((state) => getUserSesameId(state) ?? '');

  const dealsToSave: SavePayload[] = selectedOpenDeals.map((openedDeal) => ({
    dealId: openedDeal.id,
    currencyPair: `${currency1}${currency2}`,
    originalAmount: openedDeal.originalAmount,
    computedAmount: openedDeal.computedAmount,
    spotRate: openedDeal.spotRate,
    swapPoint: openedDeal.swapPoint,
    margin: openedDeal.margin,
    marketRate: openedDeal.marketRate,
    clientRate: openedDeal.clientRate,
    product: openedDeal.product,
    dealOwner: dealOwner.toUpperCase(),
    currencyPairId,
    vacationId: vacationId!,
  }));

  const dealsToConvert: DealToConvert[] = selectedOpenDeals.map((openedDeal) => ({
    dealId: openedDeal.id,
    product: openedDeal.product,
    spotRate: openedDeal.spotRate,
    swapPoint: openedDeal.swapPoint,
  }));

  const dealsToDiscard: DiscardPayload[] = selectedOpenDeals.map((openedDeal) => ({
    dealId: openedDeal.id,
    currencyPair: `${currency1}${currency2}`,
    originalAmount: openedDeal.originalAmount,
    computedAmount: openedDeal.computedAmount,
    spotRate: openedDeal.spotRate,
    swapPoint: openedDeal.swapPoint,
    margin: openedDeal.margin,
    marketRate: openedDeal.marketRate,
    clientRate: openedDeal.clientRate,
    product: openedDeal.product,
    dealOwner: dealOwner.toUpperCase(),
    currencyPairId,
    vacationId: vacationId!,
    counterparty: openedDeal.counterparty,
  }));

  return (
    <div className="d-flex p-2 bg-info justify-content-between">
      <OpenDealsSelectionButton
        selectAllOpenDeals={selectAllOpenDeals}
        selectButtonLabel={selectButtonLabel}
        isActive
      />
      <div className="d-flex gap-2">
        <DeselectButton onClick={deselectAllOpenDeals} />
        <ApplySpotRateButton selectedDeals={dealsToConvert} />
        <DiscardButton deals={dealsToDiscard} isFromBulkAction />
        <RefreshButton deals={dealsToRefresh} isFromBulkAction />
        <SaveButton
          deals={dealsToSave}
          overrideClassName="btn btn-primary-alt bg-color-primary py-1"
          isFromBulkAction
        />
      </div>
    </div>
  );
};

export default OpenDealsBulkActionsFooter;
