import { RootState } from '@/state/store';
import { PayloadAction, createSelector, createSlice, nanoid } from '@reduxjs/toolkit';

export type ErrorType = 'TechnicalError' | 'FunctionalError' | 'PayloadFormatError';

type TechnicalError = 'TE_01';
type FunctionalError = 'FE_01' | 'FE_02';
type PayloadFormatError = 'PF_01';

type ErrorCode = TechnicalError | FunctionalError | PayloadFormatError;

export type AvailableField = 'spotRate.bid' | 'spotRate.ask' | 'swapPoint.bid' | 'swapPoint.ask';

export type BackendError = {
  errorId: ErrorCode;
  description: string;
  fields?: AvailableField[];
};

export type StateBackendError = BackendError & { dealId: string; show: boolean };
export type DealsAction = 'SAVE' | 'SEND' | 'DISCARD' | 'CONVERT' | 'REFRESH' | 'DELETE';

interface ErrorsState {
  errorsToDisplay: Record<string, StateBackendError>;
}

const initialState = { displayGrowl: true, errorsToDisplay: {}, fieldsInError: {} } as ErrorsState;

const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addErrorToShow: (
      state: ErrorsState,
      action: PayloadAction<{
        stateBackendErrors: Omit<StateBackendError, 'show'>[];
        dealsAction: DealsAction;
      }>,
    ) => {
      action.payload.stateBackendErrors.forEach(({ description, errorId, fields, dealId }) => {
        state.errorsToDisplay[nanoid()] = { description, errorId, fields, dealId, show: true };
      });
    },
    hideError: (state: ErrorsState, action: PayloadAction<string>) => {
      state.errorsToDisplay[action.payload] = {
        ...state.errorsToDisplay[action.payload],
        show: false,
      };
    },
    removeErrorToShow: (state, action: PayloadAction<string>) => {
      const { [action.payload]: _removedProperty, ...remainingObject } = state.errorsToDisplay;
      state.errorsToDisplay = remainingObject;
    },
  },
});

export const { addErrorToShow, removeErrorToShow, hideError } = errorsSlice.actions;

export const getErrorsToDisplay = (state: RootState) => state.errors.errorsToDisplay;

const getDealId = (_state: RootState, dealId: string) => dealId;

export const getErrorDealById = createSelector(
  [getErrorsToDisplay, getDealId],
  (dealsInError, dealId) =>
    Object.values(dealsInError).find((stateBackendError) => stateBackendError.dealId === dealId),
);

export const getErrorInputsByDealId = createSelector(
  [getErrorsToDisplay, getDealId],
  (dealsInError, dealId) => {
    const errorForDeal = Object.values(dealsInError).find(
      (stateBackendError) => stateBackendError.dealId === dealId,
    );
    return errorForDeal?.fields ?? [];
  },
);

/**
 * Return tuple with stateId to facilite delete inside state
 */
export const getErrorByDealId = createSelector(
  [getErrorsToDisplay, getDealId],
  (dealsInError, dealId) =>
    Object.entries(dealsInError).filter(
      ([_stateID, { dealId: currentDealId }]) => currentDealId === dealId,
    ),
);
export default errorsSlice.reducer;
