import { RatesColumnsHeader } from '@/components/booking/Blotter/RatesColumnsHeader';
import { rateCellRendererSelector } from '@/components/booking/CellRendererSelector/rateCellRendererSelector';
import { ActionButtons } from '@/components/booking/DealsActions/ActionButtons';
import { smallStatusBadgeRenderSelector } from '@/components/booking/SmallStatusBadge';
import { StatusBadge } from '@/components/booking/StatusBadge';
import { Deal, Product } from '@/features/vacation/vacationModel';
import { isNotDefined } from '@sgme/fp';
import {
  CellClassParams,
  ColGroupDef,
  ICellRendererParams,
  SuppressKeyboardEventParams,
} from 'ag-grid-community';
import { DateTime as LuxonDatetime } from 'luxon';
import { IntlShape } from 'react-intl';

//todo tighten type
type MakeColumnsDefinitionParams = {
  isCurrent: boolean;
  formatMessage: IntlShape['formatMessage'];
  formatNumber: IntlShape['formatNumber'];
  currency1: string;
  currency2: string;
  convertedDealsId: string[];
  convertMetaDataPayload: {
    vacationId: string;
    currency1: string;
    currency2: string;
    currencyPairId: string;
  };
};

export const makeColumnDef = ({
  isCurrent,
  formatMessage,
  formatNumber,
  currency1,
  currency2,
  convertedDealsId,
  convertMetaDataPayload,
}: MakeColumnsDefinitionParams): ColGroupDef[] => {
  const getHeaderName = (colId: string) => formatMessage({ id: `blotter.${colId}` });

  const amountFormatter = ({ value }: { value: number }) => formatNumber(value);
  return [
    {
      headerName: '',
      headerClass: 'border-end',
      children: [
        {
          field: 'checkbox',
          hide: !isCurrent,
          headerName: '',
          minWidth: 40,
          maxWidth: 40,
          checkboxSelection: true,
          headerCheckboxSelection: false,
          showDisabledCheckboxes: true,
        },
        {
          field: 'status',
          headerName: getHeaderName('status'),
          minWidth: 60,
          type: 'numericColumn',
          cellRendererSelector: (params: ICellRendererParams<Deal>) =>
            smallStatusBadgeRenderSelector(params?.data?.id, params?.data?.status),
          cellClass: 'badgeCell',
          headerClass: 'p-0 center-aligned-header',
        },
        {
          field: 'counterparty',
          headerName: getHeaderName('counterparty'),
          headerClass: 'p-0 center-aligned-header align-items-center',
          cellClass: 'text-center',
        },
        {
          field: 'product',
          headerName: getHeaderName('product'),
          minWidth: 80,
          cellRenderer: ({ value }: { value: Product }) => <StatusBadge status={value} />,
          cellClass: 'border-end badgeCell',
          headerClass: 'border-end p-0 center-aligned-header',
        },
      ],
    },
    {
      headerName: getHeaderName('amount'),
      headerClass: 'border-end',
      children: [
        {
          field: 'amount.sell', //currency1
          valueGetter: (params) =>
            params.data.originalAmount?.currency1 || params.data.computedAmount?.currency1,
          type: 'numericColumn',
          minWidth: 110,
          cellClass: getCellClass(
            'border-end numericCell--verticalCenter numeric',
            getAmountCellClass,
            getConvertedValuesCellClass(convertedDealsId),
          ),
          headerName: currency1,
          valueFormatter: amountFormatter,
        },
        {
          field: 'amount.buy', //currency2
          minWidth: 110,
          valueGetter: (params) =>
            params.data.originalAmount?.currency2 || params.data.computedAmount?.currency2,
          type: 'numericColumn',
          headerName: currency2,
          cellClass: getCellClass(
            'border-end numericCell--verticalCenter numeric',
            getAmountCellClass,
            getConvertedValuesCellClass(convertedDealsId),
          ),
          headerClass: 'border-end ag-right-aligned-cell right-aligned-header',
          valueFormatter: ({ value }: { value: number }) => formatNumber(value),
        },
      ],
    },
    {
      headerName: getHeaderName('value.date'),
      headerClass: 'border-end',
      children: [
        {
          field: 'valueDate.theoreticalDate',
          headerName: getHeaderName('theoreticalDate'),
          minWidth: 110,
          cellRenderer: ({ value: tradeDate }: { value: string }) => (
            <div>
              {LuxonDatetime.fromISO(tradeDate).setLocale('en-US').toFormat(BLOTTER_DATE_FORMAT)}
            </div>
          ),
          cellClass: 'border-end dateCell',
          headerClass: 'border-end',
        },
        {
          field: 'valueDate.proposedDate',
          minWidth: 110,
          headerName: getHeaderName('proposed'),
          cellRenderer: ({ value, data: deal }: { value: string; data: Deal }) => {
            const theoreticalDate = LuxonDatetime.fromISO(deal.valueDate.theoreticalDate);
            const tradeDate = LuxonDatetime.fromISO(value);
            const isDifferentTheoreticalDate = !tradeDate.equals(theoreticalDate);

            return (
              <div className={isDifferentTheoreticalDate ? 'text-warning fw-semibold' : ''}>
                {tradeDate.setLocale('en-US').toFormat(BLOTTER_DATE_FORMAT)}
              </div>
            );
          },
          cellClass: 'border-end dateCell',
          headerClass: 'border-end',
        },
      ],
    },
    {
      headerName: getHeaderName('spot.rate'),
      headerClass: 'border-end',
      headerGroupComponent: !isCurrent ? null : RatesColumnsHeader,
      children: [
        {
          field: 'spotRate.bid',
          cellEditor: 'agNumberCellEditor',
          minWidth: 100,
          type: 'numericColumn',
          cellClass: 'ag-right-aligned-cell input-left',
          cellEditorParams: {
            precision: 0,
          },
          headerName: getHeaderName('bid'),
          cellRendererSelector: (params) =>
            rateCellRendererSelector(params, isCurrent, convertMetaDataPayload),
          headerClass: 'right-aligned-header',
          suppressKeyboardEvent,
        },
        {
          field: 'spotRate.ask',
          minWidth: 100,
          cellEditor: 'agNumberCellEditor',
          cellEditorParams: {
            precision: 0,
          },
          headerName: getHeaderName('ask'),
          type: 'numericColumn',
          cellClass: 'border-end ag-right-aligned-cell input-right',
          headerClass: 'border-end right-aligned-header',
          cellRendererSelector: (params) =>
            rateCellRendererSelector(params, isCurrent, convertMetaDataPayload),
          suppressKeyboardEvent,
        },
      ],
    },
    {
      headerName: getHeaderName('swap.point'),
      headerClass: 'border-end',
      headerGroupComponent: !isCurrent ? null : RatesColumnsHeader,
      children: [
        {
          field: 'swapPoint.bid',
          headerName: getHeaderName('bid'),
          minWidth: 100,
          type: 'numericColumn',
          headerClass: 'right-aligned-header',
          cellEditor: 'agNumberCellEditor',
          cellClass: 'ag-right-aligned-cell input-left',
          cellEditorParams: {
            precision: 0,
          },
          cellRendererSelector: (params) =>
            rateCellRendererSelector(params, isCurrent, convertMetaDataPayload),
          suppressKeyboardEvent,
        },
        {
          field: 'swapPoint.ask',
          headerName: getHeaderName('ask'),
          minWidth: 100,
          type: 'numericColumn',
          cellEditor: 'agNumberCellEditor',
          cellRendererSelector: (params) =>
            rateCellRendererSelector(params, isCurrent, convertMetaDataPayload),
          suppressKeyboardEvent,
          cellEditorParams: {
            precision: 0,
          },
          headerClass: 'border-end right-aligned-header',
          cellClass: 'border-end ag-right-aligned-cell input-right',
        },
      ],
    },
    {
      headerName: getHeaderName('market.rate'),
      headerClass: `${isCurrent && 'border-end'} py-1 px-1 center-header-group-cell`,
      children: [
        {
          field: 'marketRate',
          headerName: '',
          type: 'numericColumn',
          cellClass: getCellClass(
            `${isCurrent && 'border-end'} numeric numericCell--verticalCenter`,
            getConvertedValuesCellClass(convertedDealsId),
          ),
          headerClass: `${isCurrent && 'border-end'} py-1 px-1`,
        },
      ],
    },
    {
      headerName: getHeaderName('margin'),
      headerClass: `${isCurrent && 'border-end'} py-1 px-1 center-header-group-cell`,
      children: [
        {
          field: 'margin',
          type: 'numericColumn',
          headerName: '',
          cellClass: `${isCurrent && 'border-end'} numeric numericCell--verticalCenter`,
          headerClass: `${isCurrent && 'border-end'} py-1 px-1`,
        },
      ],
    },
    {
      headerName: getHeaderName('client.rate'),
      headerClass: `${isCurrent && 'border-end'} py-1 px-1 center-header-group-cell`,
      children: [
        {
          field: 'clientRate',
          headerName: '',
          type: 'numericColumn',
          cellClass: getCellClass(
            `${isCurrent && 'border-end'} numeric numericCell--verticalCenter`,
            getConvertedValuesCellClass(convertedDealsId),
          ),
          headerClass: `${isCurrent && 'border-end'} py-1 px-1`,
        },
      ],
    },
    {
      headerName: getHeaderName('actions'),
      headerClass: 'right-aligned-header-group-cell',
      children: [
        {
          field: 'actions',
          hide: !isCurrent,
          sortable: false,
          minWidth: 160,
          maxWidth: 160,
          headerName: '',
          cellClass: 'd-flex gap-2 p-0 justify-content-center align-items-center',
          cellRendererSelector: ({ data }: ICellRendererParams<Deal>) => {
            if (isNotDefined(data)) {
              return {
                component: () => null,
              };
            }
            return {
              component: () =>
                ActionButtons({
                  ...data,
                  vacationId: convertMetaDataPayload.vacationId,
                  currencyPairId: convertMetaDataPayload.currencyPairId,
                }),
            };
          },
        },
      ],
    },
  ];
};

const BLOTTER_DATE_FORMAT = 'dd LLL yyyy';

const suppressKeyboardEvent = (params: SuppressKeyboardEventParams): boolean => {
  const key = params.event.key;

  return (
    key === 'Tab' ||
    key === 'ArrowUp' ||
    key === 'ArrowDown' ||
    key === 'ArrowLeft' ||
    key === 'ArrowRight'
  );
};

function getCellClass(
  basicClasses: string,
  ...extraClassesFn: ((params: CellClassParams<Deal>) => string)[]
) {
  return (params: CellClassParams<Deal>) => {
    const addedClasses = extraClassesFn.reduce(
      (extraclasses, getExtraclass) => `${extraclasses}${getExtraclass(params)}`,
      '',
    );

    return `${basicClasses}${addedClasses}`;
  };
}

function getAmountCellClass(params: CellClassParams<Deal>) {
  const colId = params.column.getColId();
  const currency = colId === 'amount.sell' ? 'currency1' : 'currency2';
  const originalAmount = params.data?.originalAmount ?? {};
  const isOriginalAmountCurrency1 = Object.hasOwn(originalAmount, currency);

  return isOriginalAmountCurrency1 ? ' fw-bold' : '';
}

function getConvertedValuesCellClass(convertedDealsId: string[]) {
  return (params: CellClassParams<Deal>) => {
    const dealId = params.data?.id ?? '';
    const isConvertedDeal = convertedDealsId.includes(dealId);

    return isConvertedDeal ? ' text-info' : '';
  };
}
