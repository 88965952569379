import { ConvertPayload, DealsPayload, useConvertDealsMutation } from '@/api/deals.api';
import {
  EditInput,
  getUpdatedInputsByDealId,
  InputsKey,
  isBlotterReadOnly,
  updateInput,
  updateNextTabPosition,
} from '@/features/blotter/blotterSlice';
import {
  getErrorByDealId,
  getErrorInputsByDealId,
  removeErrorToShow,
  StateBackendError,
} from '@/features/errors/errorsSlice';
import { Deal } from '@/features/vacation/vacationModel';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import { isDefined } from '@sgme/fp';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { ICellRendererParams } from 'ag-grid-community';
import { ChangeEvent, useEffect, useState } from 'react';

type NumberInputProps = ICellRendererParams<Deal> & {
  readonly: boolean;
  id: string;
  fieldName: InputsKey;
  convertMetaDataPayload: {
    vacationId: string;
    currency1: string;
    currency2: string;
  };
};
export function NumberInput({
  api,
  value = '',
  data,
  readonly,
  fieldName,
  convertMetaDataPayload: { vacationId, currency1, currency2 },
}: NumberInputProps) {
  const dealId = data!.id;
  const updatedInputs = useAppSelector((state) => getUpdatedInputsByDealId(state, dealId));
  const currentInput = updatedInputs ? updatedInputs[fieldName] : null;

  const { sgwtWebAnalytics } = useSgwtWidgets();

  const fieldsInError = useAppSelector((state) => getErrorInputsByDealId(state, dealId));
  const errorToDisplay: [string, StateBackendError][] = useAppSelector((state) =>
    getErrorByDealId(state, dealId),
  );

  const isInError = fieldsInError.includes(fieldName);

  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const [convertDeal] = useConvertDealsMutation();
  const dispatch = useAppDispatch();
  const inputValue = isDefined(currentInput) ? currentInput : value;
  const tabPosition = useAppSelector((state) => state.blotter.tabPosition);

  const shouldCurrentCellBeFocused =
    tabPosition.columnId === fieldName && tabPosition.rowId === dealId;

  useEffect(() => {
    if (shouldCurrentCellBeFocused) {
      const currentRow = api.getRowNode(dealId);
      if (isDefined(currentRow)) {
        api.setFocusedCell(currentRow.rowIndex!, fieldName);
      }
    }
  }, [api, dealId, fieldName, shouldCurrentCellBeFocused]);

  const handleOnBlur = () => {
    // this means we are at the last editable field of the row
    // and therefore we should reset the cell tab position for next line
    if (fieldName === 'swapPoint.ask') {
      dispatch(updateNextTabPosition({ rowId: null, columnId: null }));
    } else {
      dispatch(updateNextTabPosition({ rowId: dealId, columnId: getNextFieldName(fieldName) }));
    }
    // we don't want to trigger a compute if value hasn't changed
    if (inputValue === value) {
      return;
    }

    const dealsToConvertPayload = prepareConvertPayload(updatedInputs, data!);

    const payload: ConvertPayload = {
      deals: dealsToConvertPayload,
      vacationId,
      currency1,
      currency2,
    };

    sgwtWebAnalytics?.trackEvent('Convert', 'User actions', fieldName);
    convertDeal(payload);
  };

  const [userInputValue, setUserInputValue] = useState<string>(inputValue);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;

    if (isInError) {
      // remove error
      errorToDisplay.forEach(([stateId]) => {
        dispatch(removeErrorToShow(stateId));
      });
    }

    setUserInputValue(targetValue);

    const isValueNotANumber = isNaN(parseFloat(targetValue));
    if (!isValueNotANumber) {
      dispatch(
        updateInput({
          dealId: data!.id, //we know data is defined as per RateCellRendererSelector
          inputKey: fieldName,
          value: parseFloat(targetValue),
        }),
      );
    }
  };

  useEffect(() => {
    const currentInput = updatedInputs ? updatedInputs[fieldName] : null;
    setUserInputValue(isDefined(currentInput) ? currentInput : value);
  }, [fieldName, updatedInputs, value]);

  return (
    <div className="input-group">
      {isInError && ( // If fiel is in error we show a tooltip
        <div className="input-icon-start">
          <i className="icon self-align-center text-danger">info_outline</i>
        </div>
      )}
      <input
        data-e2e={`${fieldName?.replace('.', '-')}-input`}
        type="number"
        step="0.01"
        readOnly={readonly || blotterReadOnly}
        className={`form-control text-end${isDefined(currentInput) ? ' text-info' : ''}${
          isInError ? ' is-invalid' : ''
        }`}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        value={userInputValue}
      />
    </div>
  );
}

const prepareConvertPayload = (updatedInputs: EditInput, deal: Deal): DealsPayload[] => {
  const { id, product } = deal;
  return [
    {
      dealId: id,
      product,
      spotRate: {
        bid: updatedInputs?.['spotRate.bid'] ?? deal.spotRate.bid,
        ask: updatedInputs?.['spotRate.ask'] ?? deal.spotRate.ask,
      },
      swapPoint: {
        bid: updatedInputs?.['swapPoint.bid'] ?? deal.swapPoint?.bid,
        ask: updatedInputs?.['swapPoint.ask'] ?? deal.swapPoint?.ask,
      },
    },
  ];
};

// todo-5059 this should be handled by exporting aggrid columns and iterating
// over it make sure we have always the right order regardless of UI changes
// but this involve refactoring blotter.tsx component
const getNextFieldName = (columnId: Exclude<InputsKey, 'swapPoint.ask'>): InputsKey => {
  switch (columnId) {
    case 'spotRate.bid': {
      return 'spotRate.ask';
    }
    case 'spotRate.ask': {
      return 'swapPoint.bid';
    }
    case 'swapPoint.bid': {
      return 'swapPoint.ask';
    }
  }
};
