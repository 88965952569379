import DefaultBulkActionsFooter from '@/components/booking/DealsBulkActionFooter/DefaultBulkActionsFooter';
import OpenDealsBulkActionsFooter from '@/components/booking/DealsBulkActionFooter/OpenDealsBulkActionsFooter';
import SavedDealsBulkActionsFooter from '@/components/booking/DealsBulkActionFooter/SavedDealsBulkActionsFooter';
import SentDealsBulkActionsFooter from '@/components/booking/DealsBulkActionFooter/SentDealsBulkActionsFooter';
import {
  getSelectedDealStatus,
  getSelectedRows,
  setSelectedRows,
  setSelectedStatusDeal,
} from '@/features/blotter/blotterSlice';
import { BookingStatus } from '@/features/vacation/vacationModel';
import { selectCurrencyPairDealsFromSessionIdVacation } from '@/features/vacation/vacationSelectors';
import { useAppDispatch, useAppSelector } from '@/hooks/reduxHook';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface DealsBulkActionsFooterProps {
  vacationId: string;
  currency1: string;
  currency2: string;
  currencyPairId: string;
}

const DealsBulkActionsFooter: FC<DealsBulkActionsFooterProps> = ({
  currencyPairId,
  vacationId,
  currency1,
  currency2,
}) => {
  const appDispatch = useAppDispatch();

  const currencyPairWithDeals = useAppSelector((state) =>
    selectCurrencyPairDealsFromSessionIdVacation(state, vacationId, { currency1, currency2 }),
  );
  const selectedDeals = useAppSelector(getSelectedRows);
  const selectedDealStatus = useAppSelector(getSelectedDealStatus) ?? '';

  const { formatMessage } = useIntl();

  /**
   * Use to track in the state the check status of rows
   *
   * Action to select/deselect all
   * If all rows are selected -> deselect all
   * If not all rows are selected -> select missing rows
   * If no rows are selected -> select all
   */
  const toggleSelectedStatus = (status: BookingStatus[]) => {
    const dealToSelect = currencyPairWithDeals.deals.filter((deal) => status.includes(deal.status));

    if (dealToSelect.length === selectedDeals.length) {
      // All deal are already selected, so we deselect
      appDispatch(setSelectedStatusDeal({ dealStatus: null }));
      appDispatch(setSelectedRows({ selectedRows: [] }));
    } else {
      appDispatch(setSelectedStatusDeal({ dealStatus: status[0] }));
      appDispatch(setSelectedRows({ selectedRows: dealToSelect }));
    }
  };

  const deselectAllDeals = () => {
    appDispatch(setSelectedStatusDeal({ dealStatus: null }));
    appDispatch(setSelectedRows({ selectedRows: [] }));
  };

  const openDeals = currencyPairWithDeals?.deals.filter((deal) =>
    ['TODO', 'INPROGRESS'].includes(deal.status),
  );
  const saveDeals = currencyPairWithDeals?.deals.filter((deal) => deal.status === 'DONE');
  const sentDeals = currencyPairWithDeals?.deals.filter((deal) => deal.status === 'SENT');

  const messagePrefix = 'blotter.dealsBulkAction';

  switch (selectedDealStatus) {
    case 'TODO':
    case 'INPROGRESS':
      return (
        <OpenDealsBulkActionsFooter
          selectAllOpenDeals={toggleSelectedStatus}
          deselectAllOpenDeals={deselectAllDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.selectedOpenDeals` },
            { count: selectedDeals.length },
          )}
          selectedOpenDeals={selectedDeals}
          currencyPairId={currencyPairId}
        />
      );
    case 'DONE':
      return (
        <SavedDealsBulkActionsFooter
          selectAllSavedDeals={toggleSelectedStatus}
          deselectAllSavedDeals={deselectAllDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.selectedSavedDeals` },
            { count: selectedDeals.length },
          )}
          selectedSavedDeals={selectedDeals}
          currencyPairId={currencyPairId}
        />
      );
    case 'SENT':
      return (
        <SentDealsBulkActionsFooter
          selectAllSentDeals={toggleSelectedStatus}
          deselectAllSentDeals={deselectAllDeals}
          selectedOpenDeals={selectedDeals}
          selectButtonLabel={formatMessage(
            { id: `${messagePrefix}.selectedSentDeals` },
            { count: selectedDeals.length },
          )}
          currencyPairId={currencyPairId}
        />
      );
    default:
      return (
        <DefaultBulkActionsFooter
          openDealsCount={openDeals?.length}
          sentDealsCount={sentDeals?.length}
          savedDealsCount={saveDeals?.length}
          selectAllOpenDeals={toggleSelectedStatus}
          selectAllSavedDeals={toggleSelectedStatus}
          selectAllSentDeals={toggleSelectedStatus}
        />
      );
  }
};

export default DealsBulkActionsFooter;
